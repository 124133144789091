import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import TableFormWrapper from 'components/atoms/FormWrapper';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ILicencesFormValues,
  ILicencesUpdateRequest,
  initialLicencesFormValues,
  initialLicencesUpdateRequest,
} from 'models/form';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { useLicences } from 'pages/LicencesPage/hooks';
import { useAlert } from 'models/alertContext';
import { formatDateTimeToIso, getDateFromISO } from 'utils/date';
import { Box } from '@mui/material';
import { LicenceForm } from '../LicenceForm';

export interface ISearchParams {
  [key: string]: any;
}

const EditLicencePage = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { handleEdit, getLicenceData, licence } = useLicences();
  const { setAlert, clearAlert } = useAlert();
  const [initialValues, setInitialValues] = useState<ILicencesFormValues | null>();

  function areArraysEqual(arr1: string[] | undefined, arr2: string[]): boolean {
    if (arr1 === undefined) return false;
    if (arr1.length !== arr2.length) {
      return false;
    }
    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();
    return sortedArr1.every((value, index) => value === sortedArr2[index]);
  }

  const hasUserMadeNoChanges = (data: any) =>
    initialValues?.contactJobTitle === data.contactJobTitle &&
    initialValues?.contactFullName === data.contactFullName &&
    initialValues?.contactDepartment === data.contactDepartment &&
    initialValues?.contactEmail === data.contactEmail &&
    initialValues?.contactPhone === data.contactPhone &&
    initialValues?.comments === data.comments &&
    formatDateTimeToIso(initialValues?.expiryDate) === formatDateTimeToIso(data.expiryDate) &&
    initialValues?.numberOfUsers.toString() === data.numberOfUsers &&
    initialValues?.licenceAdministratorPassword === data.licenceAdministratorPassword &&
    areArraysEqual(initialValues?.languagesIds, data.languagesIds);

  const handleSubmit = async (data: any) => {
    if (hasUserMadeNoChanges(data)) {
      await setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: 'Data was not changed.',
        variant: EAlertVariants.warning,
      });
      return;
    }

    try {
      const newPassword = data.licenceAdministratorPassword || false;
      const newData: ISearchParams = {};
      const requestKeys = Object.keys(initialLicencesUpdateRequest);
      Object.keys(data).map((key: string) => {
        if (requestKeys.includes(key)) {
          newData[key] = data[key] === '' ? null : data[key];
        }
        return false;
      });

      if (id) {
        await handleEdit(id, newData as ILicencesUpdateRequest, newPassword);
      }
      navigate(ERouteLinks.Licences);
    } catch (e: any) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: e.response.data.errorMessages[0],
        variant: EAlertVariants.error,
      });
    }
  };

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    (async function getLicencesDataInit() {
      if (id) {
        await getLicenceData(id);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    (async function setInitialValuesInit() {
      if (licence) {
        const values: ISearchParams = {};
        Object.keys(licence).map((key: string) => {
          if (key in initialLicencesFormValues) {
            values[key] = licence[key];
          }
          return false;
        });

        values.licenceAdministratorPassword = '';
        if (licence.licenceType) {
          values.licenceType = Object.keys(licence.licenceType).length
            ? Object.keys(licence.licenceType)[0]
            : '';
        }
        if (licence.licenceId) {
          values.licenceId = licence.licenceId;
        }

        values.expiryDate = licence.expiryDate ? getDateFromISO(values.expiryDate) : '';

        if (values.numberOfUsers === null) {
          values.numberOfUsers = '';
        }

        if (licence.startDate) {
          values.startDate = getDateFromISO(values.startDate);
        }

        const languagesIdsTmp: { [key: string]: boolean } = {};
        licence.languagesIds.map((item) => {
          languagesIdsTmp[item] = true;
          return true;
        });
        values.languagesIdsTmp = languagesIdsTmp;

        setInitialValues({ ...initialLicencesFormValues, ...values });
      }
    })();
  }, [licence]);

  return (
    <>
      <Breadcrumbs
        items={[{ label: 'Licences', route: ERouteLinks.Licences }, 'Edit Licences']}
      />
      <StyledPageTitle variant="h4">Edit Licences</StyledPageTitle>

      <TableFormWrapper>
        {licence && initialValues && (
          <LicenceForm
            onCancel={() => navigate(ERouteLinks.Licences)}
            onSubmit={handleSubmit}
            initialFormValues={initialValues}
            isEdit={true}
          />
        )}
        {initialValues?.licenceType === 'OFFLINE' && (
          <Box mt={2} sx={{ marginBottom: '10px' }}>
            <Alert
              text="Editing of the offline version of the system is only available if the license has not yet been activated."
              variant={EAlertVariants.warning}
            />
          </Box>
        )}
      </TableFormWrapper>
    </>
  );
};

export { EditLicencePage };
