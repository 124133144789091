import { ILicence } from 'models/licence';
import { IPaginator } from 'models/paginator';
import { useState } from 'react';
import { EPerPages, ESortDirectionTypes, ISortOptions } from 'models/table';
import {
  ILicencesFormValues,
  ILicencesUpdateRequest,
  IReplaceLicencesFormValues,
  ISearchLicencesFormValues,
} from 'models/form';
import { useDictionary } from 'models/dictionaryContext';
import {
  createLicences,
  generatePassword,
  getLicence,
  getLicenceKey,
  getLicences,
  replaceLicence,
  sendLicenceEmail,
  updateLicences,
  updatePassword,
} from 'requests/licence';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { EAlertVariants } from 'components/atoms/Alert';
import { getTranslations } from 'requests/translation';
import { formatDateTimeToIso } from 'utils/date';
import { ILanguages } from './helpers';

export const useLicences = () => {
  const [licences, setLicences] = useState<ILicence[]>([]);
  const [licence, setLicence] = useState<ILicence | null>();
  const [languages, setLanguages] = useState<ILanguages[]>([]);
  const [paginator, setPaginator] = useState<IPaginator | null>(null);
  const { country, getCountry, phoneCode, getPhoneCode } = useDictionary();
  const { setAlert } = useAlert();

  const getLanguages = async () => {
    const languagesData = await getTranslations(1, EPerPages.perPage100);
    if (languagesData && languagesData.content.length) {
      setLanguages(
        languagesData.content.map((item) => ({
          uniqueId: item.uniqueId,
          label: item.name,
          value: item.uniqueId,
          defaultLanguage: !!item.defaultLanguage,
        })),
      );
    } else {
      setLanguages([]);
    }
  };

  const prepareLicencesSort = (sort?: ISortOptions | null) => {
    if (!sort) {
      return {
        property: 'createdDate',
        direction: ESortDirectionTypes.DESC,
      };
    }
    return sort;
  };

  const getLicencesData = async (
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    query: Partial<ISearchLicencesFormValues> = {},
    sort?: ISortOptions | null,
  ) => {
    const licencesData = await getLicences(
      currentPage,
      perPage,
      query,
      prepareLicencesSort(sort),
    );

    if (licencesData) {
      const { content, ...paginatorData } = licencesData;
      setLicences(content);
      setPaginator(paginatorData);
    } else {
      setLicences([]);
      setPaginator(null);
    }
  };

  const getLicenceData = async (id: string) => {
    const licencesData = await getLicence(id);
    if (licencesData) {
      setLicence(licencesData);
    } else {
      setLicence(null);
    }
  };

  const getLicenceKeyData = async (id: string, licenceId: string) => {
    const licenceKeyData = await getLicenceKey(id);

    if (licenceKeyData) {
      const link = document.createElement('a');
      const date = new Date().valueOf();
      link.download = `${licenceId}_${date}.txt`;
      link.href = `data:text/txt;charset=utf-8,${encodeURI(licenceKeyData)}`;
      link.click();
    }
    return licenceKeyData;
  };

  const handleEdit = async (
    id: string,
    data: ILicencesUpdateRequest,
    newPassword: string | boolean,
  ) => {
    if (data.expiryDate) {
      data.expiryDate = formatDateTimeToIso(data.expiryDate);
    }
    const response = await updateLicences(id, { ...data });
    let responseChangePassword = true;
    if (newPassword) {
      responseChangePassword = await updatePassword(id, newPassword as string);
    }

    if (response && responseChangePassword) {
      await setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: 'Licence has been updated successfully.',
      });
    }
    return response;
  };

  const handleCreate = async (data: ILicencesFormValues) => {
    if (data.expiryDate) {
      data.expiryDate = formatDateTimeToIso(data.expiryDate);
    } else {
      delete data.expiryDate;
    }
    data.startDate = data.startDate ? formatDateTimeToIso(data.startDate) : data.startDate;
    const response = await createLicences({ ...data });

    if (response.status === 200) {
      await setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: 'Licence has been created successfully. Email notification has been sent to the administrator account.',
      });
    } else if (response.status === 400) {
      await setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: 'Something went wrong.',
        variant: EAlertVariants.error,
      });
    }
    return response;
  };

  const handleReplaceLicence = async (id: string, data: IReplaceLicencesFormValues) => {
    if (data.expiryDate) {
      data.expiryDate = formatDateTimeToIso(data.expiryDate);
    } else {
      delete data.expiryDate;
    }
    data.startDate = data.startDate ? formatDateTimeToIso(data.startDate) : data.startDate;
    const response = await replaceLicence(id, data);

    if (response.status === 200) {
      await setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: 'Licence has been replace successfully.',
      });
    } else if (response.status === 400) {
      await setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: 'Something went wrong.',
        variant: EAlertVariants.error,
      });
    }
    return response;
  };

  const loadDictionaries = async () => {
    await getCountry();
    await getPhoneCode();
  };

  const handleGeneratePassword = async () => {
    const res = await generatePassword();

    if (res) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: 'Password has been generated successfully.',
      });
    } else {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: 'Something went wrong.',
        variant: EAlertVariants.error,
      });
    }
    return res;
  };

  const sendLicenceEmailHandler = async (id: string) => sendLicenceEmail(id);

  return {
    licences,
    licence,
    paginator,
    loadDictionaries,
    getLicencesData,
    getLicenceData,
    handleCreate,
    handleEdit,
    handleGeneratePassword,
    handleReplaceLicence,
    getLicenceKeyData,
    country,
    phoneCode,
    getLanguages,
    languages,
    sendLicenceEmailHandler,
  };
};
