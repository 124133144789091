import React, { useEffect, useState } from 'react';
import {
  ILicencesEditFormValues,
  ILicencesFormValues,
  initialLicencesFormValues,
} from 'models/form';
import { EFormInputTypes, FormInput } from 'components/molecules/FormInput';
import { FormSelect } from 'components/molecules/FormSelect';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLicencesFormSchema } from 'pages/LicencesPage/schemaValidation';
import { useForm } from 'react-hook-form';
import { useLicences } from 'pages/LicencesPage/hooks';
import { colors } from 'theme/colors';
import { FormDatePicker } from 'components/molecules/FormDatePicker';
import { FormCheckbox } from 'components/molecules/FormCheckbox';
import FormLabel from 'components/atoms/FormLabel';
import FormErrorLabel from 'components/atoms/FormErrorLabel';
import { clearAlertTimeout, EAlertTime } from 'utils/date';
import { ActionTypes } from 'state/actions/alert';
import { useAlert } from 'models/alertContext';
import { Box } from '@mui/system';
import { Alert } from 'components/atoms/Alert';
import { StyledHeading } from '../styles';
import { ILanguages } from '../helpers';

export interface ILicencesForm {
  initialFormValues?: ILicencesFormValues | null;
  onCancel?: () => void;
  onSubmit: (arg: ILicencesFormValues | ILicencesEditFormValues) => void;
  isEdit?: boolean;
}

const licenceType = [
  {
    label: 'cloud / online',
    value: 'ONLINE',
  },
  {
    label: 'on-premise / offline',
    value: 'OFFLINE',
  },
];

const LicenceForm = ({
  initialFormValues,
  onCancel,
  onSubmit,
  isEdit = false,
}: ILicencesForm) => {
  const {
    loadDictionaries,
    country,
    phoneCode,
    handleGeneratePassword,
    getLanguages,
    languages,
  } = useLicences();
  const schema = useLicencesFormSchema(isEdit);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    clearErrors,
    getValues,
  } = useForm<ILicencesFormValues | ILicencesEditFormValues>({
    defaultValues: initialFormValues || initialLicencesFormValues,
    resolver: yupResolver(schema),
  });
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isSingleLanguageSelected, setIsSingleLanguageSelected] = useState<
    boolean | undefined
  >(undefined);
  const [languagesSelected, setLanguagesSelected] = useState<ILanguages[]>([]);
  const { clearAlert, samePageAlert } = useAlert();
  const defaultLanguage =
    languages.filter((it) => it.uniqueId === initialFormValues?.defaultLanguageId) ||
    languages.filter((it) => it.defaultLanguage);

  useEffect(() => {
    (async function init() {
      await loadDictionaries();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async function initLanguages() {
      await getLanguages();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset(initialFormValues || initialLicencesFormValues);
  }, [reset, initialFormValues]);

  useEffect(() => {
    if (initialFormValues && languages) {
      if (initialFormValues.languagesIds && initialFormValues.languagesIds.length) {
        setLanguagesSelected(
          languages.filter((item) => initialFormValues.languagesIds.includes(item.value)),
        );
        initialFormValues.languagesIds.map((item) => setValue(`languagesIds[${item}]`, true));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormValues, languages]);

  useEffect(() => {
    if (languagesSelected.length === 1 && !isEdit) {
      setValue('defaultLanguageId', languagesSelected[0].value);
      clearErrors('defaultLanguageId');
      setIsSingleLanguageSelected(true);
    }
    if (languagesSelected.length > 1 && isSingleLanguageSelected && !isEdit) {
      setValue('defaultLanguageId', '');
      setIsSingleLanguageSelected(false);
    }
  }, [languagesSelected]);

  useEffect(() => {
    if (defaultLanguage.length === 1 && isEdit) {
      setValue('defaultLanguageId', defaultLanguage[0].value);
    }
  }, [defaultLanguage]);

  const onChangeLanguagesSelect = (language: any) => {
    const name = language?.target?.name;
    const languageId = name.match(/\[(.*)\]/);

    if (language?.target?.checked) {
      const languageToAdd = languages.find((item) => item.value === languageId[1]);
      if (languageToAdd) {
        setLanguagesSelected([...languagesSelected, languageToAdd]);
      }
    } else {
      const { defaultLanguageId } = getValues();
      if (defaultLanguageId === languageId[1]) {
        setValue('defaultLanguageId', '');
      }

      setLanguagesSelected(languagesSelected.filter((item) => item.value !== languageId[1]));
    }
  };

  const onSubmitForm = async (data: ILicencesFormValues | ILicencesEditFormValues) => {
    setIsSubmitLoading(true);
    try {
      if (initialFormValues) {
        data.languagesIds = languagesSelected.map((item) => item.value);
        if (data?.languagesIdsTmp) {
          delete data.languagesIdsTmp;
        }

        await onSubmit(data);
      } else {
        await onSubmit(data);
      }
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const generatePassword = async () => {
    const newPassword = await handleGeneratePassword();
    if (newPassword) {
      setValue('licenceAdministratorPassword', newPassword);
      clearErrors('licenceAdministratorPassword');
      clearAlertTimeout(clearAlert, ActionTypes.CLEAR_SAME_PAGE_ALERT, EAlertTime.SHORT);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Grid container item rowSpacing={0} columnSpacing={2} lg={12}>
        <StyledHeading style={{ marginTop: 0 }}>
          <Typography variant="h5" color={colors.primary900}>
            Purchaser Details
          </Typography>
        </StyledHeading>
        <Grid item xs={12} lg={6}>
          <FormInput
            name="purchaserName"
            label="Purchaser Name*"
            control={control}
            errors={errors}
            disabled={isEdit}
            setValue={setValue}
            isClearable
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormSelect
            name="countryUniqueId"
            options={mapDictionaryToOptionProp(country)}
            label="Country*"
            control={control}
            canBeEmpty
            errors={errors}
            setValue={setValue}
            disabled={isEdit}
          />
        </Grid>
        <Grid item container rowSpacing={0} columnSpacing={2} lg={12}>
          <Grid item xs={12} md={6} lg={3}>
            <FormInput
              name="contactJobTitle"
              label="Contact Job Title*"
              control={control}
              errors={errors}
              setValue={setValue}
              isClearable
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormInput
              name="contactFullName"
              label="Contact Full Name*"
              control={control}
              errors={errors}
              setValue={setValue}
              isClearable
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormInput
              name="contactDepartment"
              label="Contact Department*"
              control={control}
              errors={errors}
              setValue={setValue}
              isClearable
            />
          </Grid>
        </Grid>
        <Grid item container rowSpacing={0} columnSpacing={2} lg={12}>
          <Grid item xs={12} md={6} lg={3}>
            <FormInput
              name="contactEmail"
              label="Contact Email*"
              control={control}
              errors={errors}
              setValue={setValue}
              isClearable
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormInput
              name="contactPhone"
              label="Contact Phone*"
              control={control}
              errors={errors}
              setValue={setValue}
              isClearable
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormInput
              name="comments"
              label="Comments"
              control={control}
              errors={errors}
              setValue={setValue}
              isClearable
            />
          </Grid>
        </Grid>

        <StyledHeading>
          <Typography variant="h5" color={colors.primary900}>
            Licence Details
          </Typography>
        </StyledHeading>

        <Grid item container rowSpacing={0} columnSpacing={2} lg={12}>
          <Grid item xs={12} lg={6}>
            <FormSelect
              name="licenceType"
              options={licenceType}
              label="Licence Type*"
              control={control}
              canBeEmpty
              errors={errors}
              setValue={setValue}
              disabled={isEdit}
            />
          </Grid>
          {isEdit && (
            <Grid item xs={12} lg={3}>
              <FormInput
                name="licenceId"
                label="Licence ID"
                control={control}
                errors={errors}
                disabled={isEdit}
                setValue={setValue}
                isClearable
              />
            </Grid>
          )}
        </Grid>
        <Grid item container rowSpacing={0} columnSpacing={2} lg={12}>
          <Grid item xs={12} md={6} lg={3}>
            <FormDatePicker
              name="startDate"
              openTo="year"
              label="Start Date*"
              control={control}
              errors={errors}
              disabled={isEdit}
              isClearable
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormDatePicker
              name="expiryDate"
              openTo="year"
              label="Expiry Date"
              footerLabel="Empty value means perpetual licence"
              control={control}
              errors={errors}
              isClearable
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormInput
              name="numberOfUsers"
              type={EFormInputTypes.number}
              label="Number of Users"
              footerLabel="Empty value means unlimited number of users"
              control={control}
              errors={errors}
              setValue={setValue}
              isClearable
            />
          </Grid>
        </Grid>
        <Grid item container rowSpacing={0} columnSpacing={2} lg={12} alignItems="flex-end">
          <Grid item xs={12} md={12} lg={4.5} xl={6}>
            <FormInput
              name="licenceAdministratorEmail"
              type={EFormInputTypes.email}
              label="Licence Administrator Email*"
              control={control}
              disabled={isEdit}
              errors={errors}
              setValue={setValue}
              isClearable
            />
          </Grid>
          <Grid item xs={12} sm={7} md={6} lg={4} xl={3}>
            <FormInput
              name="licenceAdministratorPassword"
              label="Licence Administrator Password*"
              placeholder={isEdit ? '********' : undefined}
              disabled={true}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            md={6}
            lg={3.5}
            xl={3}
            alignSelf="center"
            sx={{ paddingTop: '6px' }}
          >
            <Button
              fullWidth
              label="generate password"
              variant={EButtonVariants.outlined}
              size={EButtonSizes.small}
              onClick={generatePassword}
            />
          </Grid>
        </Grid>
        <Grid item container rowSpacing={0} columnSpacing={2} lg={12} alignItems="flex-end">
          <Grid item xs={12} md={6} lg={3}>
            <FormInput
              name="licenceFirstName"
              label="First Name*"
              control={control}
              disabled={isEdit}
              errors={errors}
              setValue={setValue}
              isClearable
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormInput
              name="licenceLastName"
              label="Last Name*"
              control={control}
              disabled={isEdit}
              errors={errors}
              setValue={setValue}
              isClearable
            />
          </Grid>
          <Grid item xs={3} md={3} lg={1.5}>
            <FormSelect
              name="licencePhonePrefixDictionaryUniqueId"
              label="Phone Prefix*"
              options={mapDictionaryToOptionProp(phoneCode)}
              control={control}
              disabled={isEdit}
              canBeEmpty
              setValue={setValue}
              errors={errors}
            />
          </Grid>
          <Grid item xs={9} md={9} lg={4.5}>
            <FormInput
              name="licencePhoneNumber"
              label="Phone Number*"
              control={control}
              disabled={isEdit}
              errors={errors}
              setValue={setValue}
              isClearable
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            {samePageAlert && (
              <Box mb={2}>
                <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
              </Box>
            )}
          </Grid>
        </Grid>

        <StyledHeading>
          <Typography variant="h5" color={colors.primary900}>
            Licence – System Translations Scope
          </Typography>
        </StyledHeading>

        <Grid item container rowSpacing={0} columnSpacing={2} lg={12}>
          {languages && (
            <Grid item xs={12} lg={12} marginBottom={1.5}>
              <FormLabel name="languagesIds" label="System Translations*" />
              <Grid item xs={12} lg={12} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {languages
                  .sort((a, b) => {
                    if (a.value === defaultLanguage[0]?.value) return -1;
                    if (b.value === defaultLanguage[0]?.value) return 1;
                    return 0;
                  })
                  .map((language: ILanguages) => (
                    <FormCheckbox
                      key={language.value}
                      withValidation={false}
                      name={`languagesIdsTmp[${language.value}]`}
                      label={language.label}
                      control={control}
                      errors={errors}
                      onChange={(e) => onChangeLanguagesSelect(e)}
                      disabled={!!(isEdit && language.value === defaultLanguage[0]?.value)}
                    />
                  ))}
              </Grid>
              {errors?.translations && !languagesSelected.length && (
                <Grid item lg={12} xs={12} style={{ marginTop: '-10px' }}>
                  <FormErrorLabel
                    label={errors?.translations?.message?.toString() as string}
                  />
                </Grid>
              )}
            </Grid>
          )}
          <Grid item xs={12} lg={12}>
            <FormSelect
              name="defaultLanguageId"
              options={isEdit ? defaultLanguage : languagesSelected}
              label="Default System Language*"
              control={control}
              setValue={setValue}
              canBeEmpty
              errors={errors}
              disabled={isEdit || languagesSelected.length === 1 || !languagesSelected.length}
              emptyPlaceholder={languagesSelected.length <= 1}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
          <Grid item xs={12} sm={6} lg={4}>
            <Button
              type="submit"
              fullWidth
              size={EButtonSizes.small}
              label={isEdit ? 'save changes' : 'ADD LICENCE'}
              variant={EButtonVariants.contained}
              isLoading={isSubmitLoading}
            />
          </Grid>
          {onCancel && (
            <Grid item xs={12} sm={6} lg={4}>
              <Button
                fullWidth
                label="Cancel"
                size={EButtonSizes.small}
                variant={EButtonVariants.outlined}
                onClick={onCancel}
              />
            </Grid>
          )}
        </Grid>

        <Grid item mt={1} xs={12}>
          <Typography variant="caption">*Mandatory field</Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export { LicenceForm };
